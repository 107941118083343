export const GET_ALL_CUSTOMERS="https://app.simplehisaab.com/api/v1/admin-portal/getCustomers"

export const GET_ALL_COMPAINES ="https://app.simplehisaab.com/api/v1/admin-portal/getCompanies"

export const SESSION_ID = localStorage.getItem("Session_Id")

export const LOGIN="https://app.simplehisaab.com/api/v1/admin-portal/login"

export const UPDATE_CUSTOMER="https://app.simplehisaab.com/api/v1/admin-portal/updateCustomer"

export const UPDATE_COMPANY="https://app.simplehisaab.com/api/v1/admin-portal/updateCompany"